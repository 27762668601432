@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

@font-face {
  font-family: "Figtree";
  src: url("./fonts/Figtree-VariableFont_wght.ttf") format("truetype");
  /* Add more font formats and sources if needed */
}

:root {
  --light-grey: #f6f9fc;
  --dark-terminal-color: #0a2540;
  --accent-color: #618b93;
  --radius: 3px;
}

.recaptcha {
  transform: scale(0.83);
  transform-origin: 0 0;
}

@media (max-width: 420px) {
  .recaptcha {
    width: 100px;
  }
}

@media (max-width: 350px) {
  .recaptcha {
    transform: scale(0.65);
    transform-origin: 0 0;
    width: 50px;
  }
}

@media (max-width: 294px) {
  .recaptcha {
    transform: scale(0.55);
    transform-origin: 0 0;
    width: 50px;
  }
}

button {
  background-color: var(--accent-color);
}

button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

input,
select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* label {
  display: block;
} */

/* a { */
/* color: var(--accent-color); */
/* font-weight: 900; */
/* } */

small {
  font-size: 0.6em;
}

fieldset,
input,
select {
  border: 1px solid #efefef;
}

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  width: 1000px;
  box-shadow: 0 1px 10px 0px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

/* 
#97D0DC - light blue
#100E0D - black
#618B93 - darker green/blue 
#FE0194 - neon pink
*/

#body {
  margin: 0;
  padding: 0;
}

.centerText {
  text-align: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

/* #root {

} */
